<template>
    <div id="app" class="container">        
        <div class="section" :style="{padding: 0.5+'rem'}">
            <template v-if=" ! isConnected">
                <div class="columns">
                    <div class="column is-twelve-mobile">
                        <div class="image" :style="{margin: '3rem auto'}">
                            <img src="img/ledpilot.svg" alt="" :style="{margin: 'auto', width: 80+'%'}">

                        </div>
                        <div class="field has-addons">

                            <input :disabled="isConnecting" type="text" class="input" v-model="peerId" placeholder="Copy your code here">
                            <button type="button" class="button is-success" :class="{'is-loading':isConnecting}" :disabled="isConnecting" @click="connect()">Connect</button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>          
                <div v-if="config">          

                    <div class="card is-fullwidth has-text-centered">
                        <div class="card-header">
                            <h2 class="card-header-title">Timer</h2>                  
                        </div>
                        <div class="card-content">
                            <div class="field">            
                                <div class="title is-1">
                                    <strong class="has-text-info">{{moment.duration(config.current.time, "seconds").format('mm:ss',{ trim: false })}}</strong>
                                    /
                                    <span v-if="part.stop">{{moment.duration(part.stop, "seconds").format('mm:ss',{ trim: false })}}</span>
                                </div>
                            </div>
                            <div class="field">
                                <p>Check realtime chrono on your board </p>         
                                <strong>(+{{moment.duration(config.current.extra, "seconds").format('mm:ss',{ trim: false })}})</strong>
                            </div>
                            <div class="field has-addons">

                                <button :disabled="config.current.isRunning" class="button is-dark" @click="send({type:'timer.previous'})">                  
                                    <span :class="'fa fa-caret-left'"></span>
                                </button>
                                <button :disabled="true" class="button is-dark is-fullwidth">
                                    {{part.name}}
                                </button>
                                <button :disabled="config.current.isRunning" class="button is-dark" @click="send({type:'timer.next'})">                  
                                    <span :class="'fa fa-caret-right'"></span>
                                </button>        
                            </div>
                            <div class="field">
                                <button class="button is-small is-dark is-fullwidth" @click="showAdvanced = ! showAdvanced">Advanced</button>
                            </div>
                            <div v-if="showAdvanced" class="field columns is-mobile is-multiline">
                                <div class="column is-6">          
                                    <label class="label has-text-left">Start Time</label>
                                    <div class="field is-grouped">
                                        <input class="input" :disabled="config.current.isRunning" :placeholder="'mm'" type="number" min="0" v-model="startTime.minutes"/>
                                        <input class="input" :disabled="config.current.isRunning" :placeholder="'ss'" type="number" min="0" v-model="startTime.seconds"/>            
                                        <button class="button is-dark" @click="setStartTime()">Set</button>
                                    </div>
                                </div>
                                <div class="column is-6">          
                                    <label class="label has-text-left">End Time</label>
                                    <div class="field is-grouped">
                                        <input class="input" :placeholder="'mm'" type="number" min="0" v-model="endTime.minutes"/>
                                        <input class="input" :placeholder="'ss'" type="number" min="0" v-model="endTime.seconds"/>
                                        <button class="button is-dark" @click="setEndTime()">Set</button>
                                    </div>
                                </div>        
                                <div class="column is-6">          
                                    <label class="label has-text-left">Extra Time</label>
                                    <div class="field is-grouped">
                                        <input class="input" :placeholder="'mm'" type="number" min="0" v-model="extraTime.minutes"/>
                                        <input class="input" :placeholder="'ss'" type="number" min="0" v-model="extraTime.seconds"/>
                                        <button class="button is-dark" @click="setExtraTime()">Set</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <footer class="card-footer">
                            <template v-if="config.current.isRunning">
                                <a class="card-footer-item" @click="send({type:'timer.stop'})">Stop</a>          
                            </template>
                            <template v-else>
                                <a class="card-footer-item" @click="confirm({type:'timer.reset'})">Reset</a>
                                <a class="card-footer-item" @click="send({type:'timer.start'})">Start</a>
                            </template>
                        </footer>            
                    </div>

                    <div class="card is-fullwidth has-text-centered">
                        <div class="card-header">
                            <h2 class="card-header-title">Scores</h2>                  
                        </div>
                        <div class="card-content">  
                            <div class="columns is-mobile">
                                <div class="column is-6">
                                    <div class="select is-fullwidth">
                                        <select  :disabled=" ! config.teams.length" @change="send({type:'teams',teams:config.current.teams})" expanded v-model="config.current.teams[0]">
                                            <option value=""></option>
                                            <option v-for="(team,t) in config.teams" :key="t" :value="team.uid">{{team.name}}</option>
                                        </select>                                              
                                    </div>
                                </div>
                                <div class="column is-6">
                                    <div class="select is-fullwidth">
                                        <select  :disabled=" ! config.teams.length" @change="send({type:'teams',teams:config.current.teams})" expanded v-model="config.current.teams[1]">
                                            <option value=""></option>
                                            <option v-for="(team,t) in config.teams" :key="t" :value="team.uid">{{team.name}}</option>
                                        </select>                                              
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column is-6">
                                    <template v-if="Array.isArray(config.current.scores[0])">
                                        <table :style="{width: 100+'%'}">
                                            <tbody>
                                                <tr>
                                                    <td :width="100 / config.current.scores[0].length + '%'" class="is-paddingless has-text-centered" v-for="(score,s) in config.current.scores[0]" :key="s">
                                                        <b-input :customClass="config.current.part == s ? 'is-hovered' : ''" class="has-text-centered" :size="'is-small'" expanded v-model="config.current.scores[0][s]"></b-input>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </template>
                                    <template v-else>                
                                        <b-input v-if="config.current.scores[0] !== false" class="has-text-centered" v-model="config.current.scores[0]"></b-input>                                
                                    </template>
                                </div>
                                <div class="column is-6">
                                    <template v-if="Array.isArray(config.current.scores[1])">
                                        <table :style="{width: 100+'%'}">
                                            <tbody>
                                                <tr>
                                                    <td :width="100 / config.current.scores[1].length + '%'" class="is-paddingless has-text-centered" v-for="(score,s) in config.current.scores[1]" :key="s">
                                                        <b-input :customClass="config.current.part == s ? 'is-hovered' : ''" class="has-text-centered" :size="'is-small'" expanded v-model="config.current.scores[1][s]"></b-input>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </template>
                                    <template v-else>                
                                        <b-input v-if="config.current.scores[1] !== false" class="has-text-centered" v-model="config.current.scores[1]"></b-input>                                
                                    </template>
                                </div>
                            </div>
                        </div>
                        <footer class="card-footer">
                            <a class="card-footer-item" @click="send({type:'score',index:0,action:'score'})">+1</a>                      
                            <a class="card-footer-item" @click="send({type:'score',index:0,action:'unscore'})">-1</a>                      
                            <a class="card-footer-item" @click="confirm({type:'score.reset'})">Reset</a>                      
                            <a class="card-footer-item" @click="send({type:'score',index:1,action:'score'})">+1</a>                      
                            <a class="card-footer-item" @click="send({type:'score',index:1,action:'unscore'})">-1</a>                      
                        </footer>
                    </div>


                    <div class="card is-fullwidth has-text-centered">  
                        <div class="card-header">
                            <h2 class="card-header-title">View</h2>                  
                        </div>            
                        <div class="card-content has-text-centered">
                            <div class="field">                
                                <div class="buttons is-centered">          
                                    <button class="button is-dark" :class="'is-grow'" @click="config.preview.type = 'Board'; send({type:'view',name:'Board'});send({type:'update'})">
                                        <span>Board</span>
                                    </button>
                                    <button class="button is-dark" :class="'is-grow'" @click="config.preview.type = 'Playlist'">
                                        <span>Playlist</span>
                                    </button>
                                    <button class="button is-dark" :class="'is-grow'" @click="config.preview.type = 'Panel'">
                                        <span>Panel</span>
                                    </button>
                                    <button class="button is-dark" :class="'is-grow'" @click="config.preview.type = 'Ledpilot'">
                                        <span>Ledpilot</span>
                                    </button>
                                </div>
                            </div>
                            <template v-if="config.preview.type == 'Playlist'">            

                                <div class="field">
                                    <div class="columns is-marginless is-multiline">
                                        <div :style="{padding: 0.25+'rem'}" class="column is-3" v-for="(playlist,p) in config.playlists" :key="p">
                                            <button class="button is-dark is-small is-fullwidth"  :key="p" @click="send({type:'view',name:'Playlist',uid:playlist.uid});send({type:'update'})">{{playlist.name}}</button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="config.preview.type == 'Panel'">            

                                <div class="field">
                                    <div class="columns is-marginless is-multiline">
                                        <div :style="{padding: 0.25+'rem'}" class="column is-3" v-for="(panel,p) in config.panels" :key="p">
                                            <button class="button is-dark is-small is-fullwidth"  :key="p" @click="send({type:'view',name:'Panel',uid:panel.uid});send({type:'update'})">{{panel.name}}</button>
                                        </div>
                                    </div>
                                </div>
                            </template>       
                            <template v-if="config.preview.type == 'Ledpilot'">            

                                <div class="field has-addons">
                                    <input class="input is-dark" expanded v-model="config.ledpilot" />
                                    <button class="button is-black" @click="send({type:'view',name:'Ledpilot',uid:config.ledpilot});send({type:'update'})">ok</button>                                    
                                    
                                </div>
                            </template>          
                        </div>
                    </div>
                    <div class="card is-fullwidth">
                        <header class="card-header">
                            <p class="card-header-title">
                                <span>Teams</span>
                            </p>
                        </header>
                        <section class="card-content"> 
                            <b-tabs position="is-centered" class="block">                         
                                                       
                                <b-tab-item  v-for="(team,t) in teams" v-bind:label="team ? team.name : 'None'" v-bind:key="t">

                                    <template v-if="team && players[t]">
                                        
                                      
                                        <b-table ref="players" 
                                        :data="players[t]"                            
                                        :striped="true"
                                        :narrowed="true"
                                        :default-sort="'isBackup'"
                                        :mobile-cards="false"

                                        >
                                            <b-table-column :custom-sort="sortByBackup" sortable numeric field="isBackup" width="40" v-slot="props">
                                                <b-icon :size="'is-small'" :icon="'dot-circle'" :type="props.row.isBackup ? 'is-danger':'is-success'"></b-icon>
                                            </b-table-column>
                                            <b-table-column sortable field="number" label="#" width="40" v-slot="props">
                                                {{ props.row.number }}
                                            </b-table-column>
                                            <b-table-column sortable :field="'name'" label="Name"  v-slot="props">
                                                {{ props.row.name }}
                                            </b-table-column>
                                            <b-table-column  label=" " width="40" v-slot="props">
                                                <b-dropdown :position="'is-top-left'" class="has-text-left">
                                                    <button class="button is-small" slot="trigger">
                                                        <b-icon icon="caret-down" :type="props.row.isBackup ? 'is-danger':'is-success'"></b-icon>
                                                    </button>
                                                    <b-dropdown-item  v-for="(button,b) in type.player.actions" :key="b" @click="send({index:t,type:button,action:type.actions[button].action,team:team.uid,player:props.row.uid})" v-slot="props">
                                                        <b-icon :icon="type.actions[button].icon" :type="type.actions[button].type"></b-icon>
                                                        <span>{{type.actions[button].label}}</span>                                                    
                                                    </b-dropdown-item>
                                                </b-dropdown>
                                            </b-table-column>-->
                                                                 

                                        </b-table> 
                                </template>
                                <template v-else>
                                    <p class="has-text-centered has-text-light">No team selected</p>
                                </template>

                            </b-tab-item>
                        </b-tabs>
                    </section>

                </div>                        

            </div>
            <div class="has-text-centered">
                <small>
                    <a @click="disconnect()">Disconnect</a>
                </small>            
            </div>
        </template>
    </div>
</div>
</template>

<script>
    import moment from 'moment'
    import { io, Socket } from "socket.io-client";
    import 'moment-duration-format'

    export default {
        data()
        {
            return {
                
                room: null,
                syncData: null,
                moment: moment,
                showAdvanced: false,
                isPeered: false,
                peerId: localStorage.getItem('peerId'),
                isConnected: false,
                isConnecting: false,
                reconnectTimer: null,
                peer: null,
                conn: null,
                config: null,
                parts: [],
                startTime: {
                    minutes: null,
                    seconds: null,
                },
                endTime: {
                    minutes: null,
                    seconds: null,
                },
                extraTime: {
                    minutes: null,
                    seconds: null,
                }, 
                socket:Socket,
                
            }
        },
        mounted()
        {
            console.log(this.config)
            
          // window.addEventListener('blur',this.disconnect)
      },
      computed:{
           
          players()
          {
              if(this.config)
              {
                  return [
                  this.teams[0] ? this.teams[0].players : [],
                  this.teams[1] ? this.teams[1].players : [],
                  ]                
              }
              return []
          },          
          teams()
          {
              if(this.config)
              {

                  return [
                  this.config.teams.find(t => t.uid === this.config.current.teams[0]),
                  this.config.teams.find(t => t.uid === this.config.current.teams[1]),
                  ]
              }
              return []
          },
          part()
          {
              if(this.config && this.parts)
              {
                  return this.parts[this.config.current.part]
              }else{
                  return null
              }
          }        
      },
      methods:{
         sortByBackup(a, b, isAsc) 
         {
            if ( ! isAsc) {
              return (a.isBackup === b.isBackup)? 0 : a.isBackup ? -1 : 1
            } else {
              return (a.isBackup === b.isBackup)? 0 : a.isBackup ? 1 : -1
            }
        },    
          setStartTime()
          {
              this.send({type:'timer.starttime',time: this.startTime})
          },
          setEndTime()
          {
              this.send({type:'timer.endtime',time: this.endTime})
          },
          setExtraTime()
          {

              this.send({type:'timer.extratime',time: this.extraTime})
          },          
          disconnect()
          {
              if(this.peer)
              {               
                  this.peer.destroy()   
              }
          },
          connect()
          {
              /*clearTimeout(this.reconnectTimer)
              if(this.peer) {
                  console.log('connection exists')
                  this.peer.destroy()
              }*/
            this.socket = io('http://ledscore.ledpilot.eu:3000', { transports : ['websocket'] });
            console.log('initiate socket',this.socket)
            
            this.socket.emit("joinRoom",'ledpilotscore_'+this.peerId.toLowerCase())

              localStorage.setItem('peerId',this.peerId)

                this.socket.on("roomJoined", (room) => {
                    console.log('confirm connected', room)
                    this.room = room
                    this.socket.emit("getSync",'ledpilotscore_'+this.peerId.toLowerCase())
                    this.isConnecting = true
                    
                });
                this.socket.on("disconnecting", () => {
                    this.onConnectionClose
                });
                this.socket.on("syncRemote", (syncData)=>{
                    this.syncData = syncData
                    console.log(this.syncData)
                    this.onConnectionOpen()
                    this.syncRemote()
                    
                }) 

              
          },
          onDisconnected()
          {
              console.log('onDisconnected')
              clearTimeout(this.reconnectTimer)
              this.reconnectTimer = setTimeout(this.peer.reconnect,3000)

          },  
          onClose()
          {
              console.log('onClose')
              this.isConnected = false
              this.isConnecting = false              
              
              this.peer.off('error', this.onError)
              this.peer.off('disconnected', this.onDisconnected)
              this.peer.off('close', this.onClose)   
          },        
          onError(e)
          {
              console.log('onError',e)
              this.isConnected = false
              this.isConnecting = false

          },
          onConnectionError()
          {        
              console.log('onConnectionError')
          },          
          onConnectionOpen()
          {
              console.log('onConnectionOpen')
              this.isConnecting = false
              this.isConnected = true
              
          },  
          onConnectionClose()
          {            
              console.log('onConnectionClose')
              this.isConnected = false

              this.conn.off('open', this.onConnectionOpen)
              this.conn.off('close', this.onConnectionClose)
              this.conn.off('data', this.onConnectionData);
              this.conn.off('error', this.onConnectionError);                      

          },
          syncRemote()
          {

             console.log("syncdata",this.syncData)
              if(this.syncData.parts)
              {
                  this.parts = this.syncData.parts
              } 
              if(this.syncData.config)
              {
                  this.config = this.syncData.config
                  console.log(this.config)
              }
              if(this.syncData.type)
              {
                  this.type = this.syncData.type
              }
              if(this.syncData.time)
              {
                   this.config.current.time = this.syncData.time
              }
          },
          confirm(message)
          {
              if(confirm('Sure ?')) this.send(message)
          },
          send(message)
          {   
              console.log("send action", this.room, message)
              this.socket.emit("updateBoard",this.room,message)           
              //if(this.conn && this.conn.open) this.conn.send(message)      

          },
  }    
}
</script>
<style>

@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700');
@import url('https://unpkg.com/bootstrap/dist/css/bootstrap.min.css');
@import url('https://unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.css');

@import "assets/css/legacy-css.css";

</style>
